import theme from '@/style';
import {View} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import {BasicObject} from '@/types';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

export interface DigitBallProps {
  ballSize?: number;
  digit: string;
  color: string;
  fontSize?: number;
  emptyType?: string;
}

const COLOR_MAP = {
  A: '#DE3C3F',
  B: '#EC8305',
  C: '#046FEA',
} as BasicObject;

const DigitBall = ({
  ballSize,
  digit,
  color = 'A',
  fontSize,
  emptyType = '*',
}: DigitBallProps) => {
  const {calcActualSize} = useScreenSize();
  const _ballSize = ballSize || calcActualSize(32);
  const _fontSize = fontSize || calcActualSize(12);
  return (
    <View
      style={[
        theme.flex.center,
        // eslint-disable-next-line react-native/no-inline-styles
        {
          width: _ballSize,
          height: _ballSize,
          borderRadius: _ballSize / 2,
          backgroundColor:
            digit === '-' && emptyType === '*' ? '#C6C6C6' : COLOR_MAP[color],
        },
      ]}>
      <Text
        fontSize={_fontSize}
        fontFamily="fontInterBold"
        color={theme.basicColor.white}>
        {digit}
      </Text>
    </View>
  );
};

export default DigitBall;
