import Drawer, {DrawerRef} from '@/components/basic/game-drawer/game-drawer';
import React, {forwardRef, useEffect, useImperativeHandle} from 'react';
import Text from '@/components/basic/text';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import theme from '@/style';
import {ReactComponent as CloseIcon} from '@/assets/icons/games/close.svg';
import {ReactComponent as QuestionIcon} from '@/assets/icons/games/question.svg';
import {ReactComponent as DigitClock} from '@/assets/icons/games/digit-clock.svg';
import {ReactComponent as DigitClockOpening} from '@/assets/icons/games/digit-clock-opening.svg';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import {ScrollView, View} from 'react-native';
import {useTranslation} from 'react-i18next';
import {betsListItem, PickInfosContent} from '../../digit-service';
import BetsDigitLevelItem from '../../components/bets-digit-level';
import parabolaService from '@/components/basic/parabola/parabola.service';
import ShopCart from '@/components/business/games/cart/cart';
import BetsItem from '../../components/bets-item';
import BetsDigit from '../../bets-digit';
import {useConfirm} from '@/components/basic/modal';
export type ShopCartDrawerRef = {
  open: () => void;
};

interface ShopCartDrawerProps {
  remainMillis?: number;
  onRule?: () => void;
  minBetSeconds?: number;
  levelList?: PickInfosContent[];
  issueNo?: string;
  onPaySubmit?: (
    betsList: betsListItem[],
    priceAndNum: {
      total: number;
      num: number;
    },
    cb?: () => void,
  ) => void;
}

const ShopCartDrawer = (
  {
    remainMillis = 0,
    onRule,
    minBetSeconds = 30,
    levelList = [],
    issueNo,
    onPaySubmit,
  }: ShopCartDrawerProps,
  ref: React.Ref<ShopCartDrawerRef>,
) => {
  const drawerRef = React.useRef<DrawerRef>(null);
  const onClose = () => {
    drawerRef.current?.close();
  };
  const onOpen = () => {
    drawerRef.current?.open();
  };
  useImperativeHandle(ref, () => ({
    open: onOpen,
  }));
  const remainTimeStr = React.useMemo(() => {
    const m = Math.floor((remainMillis % 3600000) / 60000);
    const s = Math.floor((remainMillis % 60000) / 1000);
    const msf = Math.floor((remainMillis % 1000) / 100);
    return `${m.toString().padStart(2, '0')}:${s
      .toString()
      .padStart(2, '0')}:${msf}`;
  }, [remainMillis]);
  const remainSeconds = React.useMemo(() => {
    return Math.floor(Math.max(remainMillis, 0) / 1000);
  }, [remainMillis]);
  const {calcActualSize} = useScreenSize();
  const {t} = useTranslation();
  const endElement = React.useRef<View>(null);
  const [betsList, setBetsList] = React.useState<betsListItem[]>([]);
  const {renderModal: renderConfirmModal, show: confirmShow} = useConfirm(
    t('label.del'),
  );

  useEffect(() => {
    setBetsList([]);
  }, [issueNo]);

  const timeDisabled = React.useMemo(() => {
    return remainSeconds < minBetSeconds;
  }, [minBetSeconds, remainSeconds]);

  const remainSecondsBySecond = React.useMemo(() => {
    return Math.floor(remainSeconds);
  }, [remainSeconds]);

  const handleAdd = ({
    level,
    type,
    value,
    count,
    basePrice,
    pickInfoId,
  }: {
    level: number;
    type: string;
    value: string;
    count: number;
    basePrice: number;
    pickInfoId: number;
  }) => {
    // 这里优先阻止一下添加，type跟value长度不一致不应该添加购买
    if (type.length !== value.length) {
      return;
    }
    const hasItem = betsList.find(
      item => item.value === value && item.type === type,
    );
    if (hasItem) {
      const newList = betsList.map(item => {
        if (item.value === value && item.type === type) {
          return Object.assign({}, item, {count: item.count + count});
        }
        return item;
      });
      setBetsList(newList);
    } else {
      betsList.push({
        level,
        type,
        value,
        count,
        basePrice,
        pickInfoId,
      });
      setBetsList([...betsList]);
    }
  };

  const handleBox = ({
    value,
    count,
    basePrice,
    pickInfoId,
  }: {
    value: string;
    count: number;
    basePrice: number;
    pickInfoId: number;
  }) => {
    if (value.length !== 3) {
      return;
    }
    const valueArr = value.split('');
    if (valueArr[0] === valueArr[1] && valueArr[0] === valueArr[2]) {
      handleAdd({
        value: valueArr.join(''),
        type: 'ABC',
        count,
        level: 3,
        basePrice,
        pickInfoId,
      });
      return null;
    }
    const values = ['012', '021', '102', '120', '201', '210'];
    values.forEach(item => {
      const itemIndex = item.split('');
      // const types = itemIndex.map(pie => ['A', 'B', 'C'][Number(pie)]).join('');
      const targetV = itemIndex.map(pie => valueArr[Number(pie)]).join('');
      handleAdd({
        value: targetV,
        type: 'ABC',
        count,
        level: 3,
        basePrice,
        pickInfoId,
      });
    });
  };

  const priceAndNum = React.useMemo(() => {
    let totalPrice = 0;
    let totalNum = 0;
    if (betsList.length > 0) {
      betsList.map(item => {
        totalPrice += item.count * item.basePrice;
        totalNum += item.count;
      });
    }
    return {
      total: totalPrice,
      num: totalNum,
    };
  }, [betsList]);

  const payDisabled = React.useMemo(() => {
    return timeDisabled || betsList.length === 0;
  }, [timeDisabled, betsList]);

  return (
    <Drawer
      ref={drawerRef}
      style={[theme.position.rel]}
      contentBackgroundColor="transparent"
      mode="bottom">
      <View
        style={[
          theme.flex.row,
          theme.flex.alignStart,
          theme.flex.end,
          theme.fill.fillW,
          theme.position.abs,
          {
            top: -calcActualSize(44),
            right: 0,
            height: calcActualSize(44),
            gap: calcActualSize(12),
            paddingHorizontal: calcActualSize(12),
          },
        ]}>
        {!timeDisabled && (
          <View
            style={[
              theme.fill.fill,
              theme.position.abs,
              theme.flex.row,
              theme.flex.alignStart,
              theme.flex.centerByRow,
              {gap: calcActualSize(4)},
            ]}>
            <DigitClock
              width={calcActualSize(20)}
              height={calcActualSize(20)}
            />
            <Text calc size="large" color={'#FDE047'} blod>
              {remainTimeStr}
            </Text>
          </View>
        )}
        <NativeTouchableOpacity onPress={onRule}>
          <QuestionIcon />
        </NativeTouchableOpacity>
        <NativeTouchableOpacity onPress={onClose}>
          <CloseIcon />
        </NativeTouchableOpacity>
      </View>
      <View
        style={[
          {
            paddingHorizontal: calcActualSize(12),
            paddingTop: calcActualSize(12),
            gap: calcActualSize(12),
            borderTopLeftRadius: calcActualSize(8),
            borderTopRightRadius: calcActualSize(8),
            overflow: 'hidden',
            backgroundColor: '#F5F5F5',
          },
        ]}>
        <Text calc main size="medium" blod>
          {t('game-page.label.bet')}
        </Text>
        <ScrollView
          style={{height: calcActualSize(440)}}
          contentContainerStyle={{
            gap: calcActualSize(12),
            paddingBottom: calcActualSize(12),
          }}>
          {levelList.map((item, _i) => (
            <BetsDigitLevelItem
              key={_i}
              onAdd={(_t, v, c, startElement) => {
                parabolaService.start({
                  startWith: startElement!,
                  endWith: endElement.current!,
                });
                handleAdd({
                  type: _t,
                  value: v,
                  count: c,
                  level: item.pickLevel,
                  basePrice: item.pickAmount,
                  pickInfoId: item.pickInfoId,
                });
              }}
              handleBox={(v, c, startElement) => {
                parabolaService.start({
                  startWith: startElement!,
                  endWith: endElement.current!,
                });
                handleBox({
                  value: v,
                  count: c,
                  basePrice: item.pickAmount,
                  pickInfoId: item.pickInfoId,
                });
              }}
              winPrize={item.pickWinAmount}
              payment={item.pickAmount}
              level={item.pickLevel}
            />
          ))}
        </ScrollView>
      </View>
      <ShopCart
        endRef={endElement}
        tip={`${priceAndNum.num} ${t('game-page.label.numbers')}`}
        price={priceAndNum.total}
        title={t('game-page.label.myNum')}
        onClear={() => {
          setBetsList([]);
        }}
        renderButton={
          <NativeTouchableOpacity
            onPress={() => {
              if (payDisabled) {
                return;
              }
              onPaySubmit?.(betsList, priceAndNum, () => {
                setBetsList([]);
              });
              // drawerRef.current?.close();
            }}
            style={[
              {
                width: calcActualSize(124),
                height: calcActualSize(44),
                borderRadius: calcActualSize(8),
                opacity: payDisabled ? 0.3 : 1,
              },
              theme.flex.center,
              theme.background.primary,
            ]}>
            <Text calc fontSize={14} color={'white'} blod>
              {t('game-page.label.pay')}
            </Text>
          </NativeTouchableOpacity>
        }>
        <ScrollView
          style={[theme.margin.topl]}
          contentContainerStyle={[
            theme.flex.row,
            theme.padding.lrl,
            theme.padding.tops,
            // eslint-disable-next-line react-native/no-inline-styles
            {flexWrap: 'wrap', gap: 12},
            theme.background.white,
          ]}>
          {betsList.map((item, _i) => (
            <BetsItem
              onClose={() => {
                const copy = [...betsList];
                copy.splice(_i, 1);
                setBetsList(copy);
              }}
              type={item.type}
              digit={item.value}
              rate={item.count}
              key={_i}
            />
          ))}
        </ScrollView>
      </ShopCart>
      {!!timeDisabled && (
        <View
          style={[
            theme.fill.fill,
            theme.position.abs,
            {
              top: 0,
              left: 0,
              backgroundColor: 'rgba(0,0,0,0.5)',
              zIndex: 10000,
            },
            theme.flex.center,
          ]}>
          <View style={[theme.flex.col, theme.flex.center]}>
            <DigitClockOpening
              width={calcActualSize(39)}
              height={calcActualSize(39)}
            />
            <Text calc fontSize={24} color={'#FDE047'} blod>
              {t('game-page.label.openingIn', {second: remainSecondsBySecond})}
            </Text>
          </View>
        </View>
      )}
      <Drawer
        beforeClose={() => {}}
        ref={drawerRef}
        mode="bottom"
        contentBackgroundColor="#0000">
        <BetsDigit
          digitList={betsList}
          onDigitClose={_i => {
            const copy = [...betsList];
            copy.splice(_i, 1);
            setBetsList(copy);
          }}
          onClose={() => {
            if (betsList.length === 0) {
              drawerRef.current?.close();
            } else {
              setTimeout(() => {
                confirmShow(
                  t('label.del'),
                  'Please confirm you want to clear all numbers?',
                  () => {
                    setBetsList([]);
                  },
                );
              }, 0);
            }
          }}
        />
      </Drawer>
      {renderConfirmModal}
    </Drawer>
  );
};

export default forwardRef(ShopCartDrawer);
