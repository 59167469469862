import {View} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import DigitBall from '../../components/digit-ball';
import theme from '@/style';
import dayjs from 'dayjs';

export interface DrawResultProps {
  wonCode?: string;
  drawTime?: number;
}

const COLORS = ['A', 'B', 'C'];

const DrawResult = ({wonCode = '***', drawTime}: DrawResultProps) => {
  const result = React.useMemo(() => {
    return wonCode ? wonCode.split('') : ['*', '*', '*'];
  }, [wonCode]);

  return (
    <View style={[theme.flex.centerByCol]}>
      <View style={[theme.flex.row, theme.flex.centerByCol]}>
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          <Text size="medium" accent>
            {dayjs(drawTime).format('DD-MM-YYYY')}
          </Text>
          <Text
            size="medium"
            fontFamily="fontInterBold"
            color={theme.basicColor.dark}>
            {' '}
            {dayjs(drawTime).format('hh:mm A')}
          </Text>
        </View>
        <Text
          size="medium"
          fontFamily="fontInterBold"
          style={[theme.margin.lefts]}>
          Draw results:
        </Text>
      </View>
      <View
        style={[
          theme.flex.row,
          theme.flex.centerByCol,
          theme.margin.tops,
          {
            gap: theme.paddingSize.xs,
          },
        ]}>
        {['A', 'B', 'C'].map((item, _i) => (
          <DigitBall
            key={_i}
            digit={item}
            color={item}
            ballSize={32}
            fontSize={16}
          />
        ))}
        <Text size="medium" fontFamily="fontInterBold">
          =
        </Text>
        {result.map((item, _i) => (
          <DigitBall
            key={_i}
            digit={item}
            color={COLORS[_i]}
            ballSize={32}
            fontSize={16}
          />
        ))}
      </View>
    </View>
  );
};

export default React.memo(DrawResult);
