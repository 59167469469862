import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import theme from '@/style';
import {View, StyleSheet, Image} from 'react-native';
import Text from '@basicComponents/text';
import {useTranslation} from 'react-i18next';
import React, {useEffect, useMemo, useState} from 'react';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Analyze from '@/components/svgs/basic/analyze';
import {downIcon} from '@/common-pages/rebate/rebate.variables';
import QuickDigitNumbers from './quick-digit-numbers';
import QuickDigitHeaderStatus from './quick-digit-header-status';
import RuleModal from '@/components/business/games/rule';
import DigitRule from '../../digit-rule';
import Video from '@/components/basic/video';
interface QuickDigitHeaderProps {
  issueNo?: string;
  cycle?: number;
  // 上一期的开奖结果
  numResult?: string;
  // 开奖版本，每一次开奖后版本号+1
  prizeVersion?: number;
  minBetSeconds?: number;
  remainingTime?: number;
  onTimeEnd?: () => void;
  onTimeChange?: (remainMillis: number) => void;
  onAnalyze?: () => void;
}

const QuickDigitHeader = ({
  cycle = 3,
  numResult = '000',
  prizeVersion = 0,
  minBetSeconds = 30,
  remainingTime = 0,
  issueNo = '--',
  onTimeEnd,
  onAnalyze,
  onTimeChange,
}: QuickDigitHeaderProps) => {
  const {calcActualSize} = useScreenSize();
  const {t} = useTranslation();
  const [playerBetting, setPlayerBetting] = useState(1);
  const [ruleVisible, setRuleVisible] = useState(false);
  const currentPlayerBettingTime = React.useRef<number>(0);
  const [remainMillis, setRemainMillis] = useState(0);
  const [cutdownPaused, setCutdownPaused] = useState(true);
  const [rollPaused, setRollPaused] = useState(true);
  const styles = StyleSheet.create({
    headerRightBtn: {
      height: calcActualSize(24),
      backgroundColor: 'rgba(255, 255, 255, 0.70)',
      borderRadius: calcActualSize(4),
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
    },
  });
  const [rolling, setRolling] = useState(false);
  const rollingTimer = React.useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    setRolling(true);
    if (rollingTimer.current) {
      clearTimeout(rollingTimer.current);
      rollingTimer.current = null;
    }
    setPlayerBetting(
      Math.max(
        0,
        (cycle * 60 - remainingTime) * Math.floor(1 + Math.random() * 4),
      ),
    );
    rollingTimer.current = setTimeout(
      () => {
        setRolling(false);
        setRollPaused(false);
      },
      prizeVersion === 0 ? 1000 : 2000,
    );
  }, [prizeVersion, remainingTime, cycle]);

  const handleHowToPlay = () => {
    setRuleVisible(true);
  };

  const handleTimeChange = (remainMillis: number) => {
    onTimeChange?.(remainMillis);
    if (
      currentPlayerBettingTime.current <= 0 ||
      currentPlayerBettingTime.current - remainMillis >= 3000
    ) {
      setPlayerBetting(betting => betting + Math.floor(1 + Math.random() * 15));
      currentPlayerBettingTime.current = remainMillis;
    }
    setRemainMillis(remainMillis);
  };

  const timeRemain = useMemo(() => {
    return Math.floor(Math.max(remainMillis, 0) / 1000);
  }, [remainMillis]);

  useEffect(() => {
    if (timeRemain === 3) {
      setCutdownPaused(false);
    }
  }, [timeRemain]);

  useEffect(() => {
    currentPlayerBettingTime.current = 0;
  }, [issueNo]);

  return (
    <View
      style={[
        theme.flex.col,
        {backgroundColor: theme.backgroundColor.quickDigitChatHeaderBg},
      ]}>
      <View style={[theme.flex.col, theme.position.rel]}>
        <View
          style={[
            theme.flex.row,
            theme.flex.centerByCol,
            theme.flex.between,
            {
              paddingHorizontal: calcActualSize(12),
              paddingVertical: calcActualSize(8),
            },
          ]}>
          <Text size="small" calc>
            {playerBetting + ' '}
            {t('game-page.label.playerBetting')}
          </Text>
          <View
            style={[
              theme.flex.row,
              theme.flex.centerByCol,
              {gap: calcActualSize(8)},
            ]}>
            <NativeTouchableOpacity
              style={[
                styles.headerRightBtn,
                {paddingHorizontal: calcActualSize(8)},
              ]}
              onPress={handleHowToPlay}>
              <Text calc size="small" second>
                {t('game-page.label.howToPlay')}
              </Text>
            </NativeTouchableOpacity>
            <NativeTouchableOpacity
              style={[
                styles.headerRightBtn,
                theme.flex.between,
                {
                  paddingHorizontal: calcActualSize(6),
                  width: calcActualSize(59),
                },
              ]}
              onPress={onAnalyze}>
              <Analyze width={calcActualSize(20)} height={calcActualSize(20)} />
              <Image
                style={[
                  {width: calcActualSize(14), height: calcActualSize(14)},
                ]}
                source={downIcon}
              />
            </NativeTouchableOpacity>
          </View>
        </View>
        <QuickDigitNumbers nums={numResult} rolling={rolling} />
        {timeRemain <= 3 && (
          <View
            style={[
              theme.flex.center,
              theme.fill.fill,
              theme.position.abs,
              {backgroundColor: '#00000080'},
            ]}>
            <Text calc fontFamily="fontDin" fontSize={72} color="white" blod>
              {timeRemain}
            </Text>
          </View>
        )}
      </View>
      <QuickDigitHeaderStatus
        issueNo={issueNo}
        minBetSeconds={minBetSeconds}
        remainingTime={remainingTime}
        onTimeEnd={onTimeEnd}
        onTimeChange={handleTimeChange}
      />

      <RuleModal
        title="Rule"
        visible={ruleVisible}
        scrollEnabled={false}
        onClose={() => setRuleVisible(false)}>
        <DigitRule gameName={`Quick3D ${cycle}min`} />
      </RuleModal>
      <Video
        onEnd={() => {
          setCutdownPaused(true);
          // videoRef.current?.seek(0);
        }}
        audioOnly
        source={require('@components/assets/music/cut-down.mp3')}
        src={'/assets/mp3/cut-down.mp3'}
        paused={cutdownPaused}
      />
      <Video
        onEnd={() => setRollPaused(true)}
        audioOnly
        paused={rollPaused}
        source={require('@components/assets/music/rolling.mp3')}
        src="/assets/mp3/rolling.mp3"
      />
    </View>
  );
};

export default QuickDigitHeader;
