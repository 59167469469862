import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import Text from '@/components/basic/text';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import theme from '@/style';
import React, {useState} from 'react';
import {View} from 'react-native';
import {useTranslation} from 'react-i18next';
import ShopCartDrawer, {ShopCartDrawerRef} from './shop-cart-drawer';
import RuleModal from '@/components/business/games/rule';
import DigitRule from '../../digit-rule';
import {betsListItem, PickInfosContent} from '../../digit-service';
interface DigitChatProps {
  remainMillis?: number;
  cycle?: number;
  minBetSeconds?: number;
  levelList?: PickInfosContent[];
  issueNo?: string;
  onPaySubmit?: (
    betsList: betsListItem[],
    priceAndNum: {total: number; num: number},
    cb?: () => void,
  ) => void;
}
const DigitChat = ({
  remainMillis,
  cycle = 3,
  minBetSeconds = 30,
  levelList = [],
  issueNo,
  onPaySubmit,
}: DigitChatProps) => {
  const {calcActualSize} = useScreenSize();
  const {t} = useTranslation();
  const shopCartDrawerRef = React.useRef<ShopCartDrawerRef>(null);
  const [ruleVisible, setRuleVisible] = useState(false);
  return (
    <View style={[theme.flex.flex1]}>
      <View
        style={[
          theme.flex.flex1,
          theme.flex.center,
          theme.background.lightGrey,
        ]}>
        <Text calc>CHATCONTENT</Text>
      </View>
      <View
        style={[
          theme.flex.row,
          theme.flex.centerByCol,
          {
            gap: calcActualSize(24),
            height: calcActualSize(56),
            paddingHorizontal: calcActualSize(12),
            backgroundColor: '#FFFFFF',
          },
        ]}>
        <View style={[theme.flex.flex1]}>
          <Text calc>COMMENT</Text>
        </View>
        <NativeTouchableOpacity
          style={[
            theme.flex.center,
            {
              height: calcActualSize(44),
              width: calcActualSize(124),
              borderRadius: calcActualSize(12),
            },
            theme.background.main,
          ]}
          onPress={() => {
            shopCartDrawerRef.current?.open();
          }}>
          <Text calc fontSize={18} color={'white'} blod>
            {t('play')}
          </Text>
        </NativeTouchableOpacity>
      </View>
      <ShopCartDrawer
        ref={shopCartDrawerRef}
        remainMillis={remainMillis}
        onRule={() => setRuleVisible(true)}
        minBetSeconds={minBetSeconds}
        levelList={levelList}
        issueNo={issueNo}
        onPaySubmit={onPaySubmit}
      />
      <RuleModal
        title="Rule"
        zIndex={10001}
        visible={ruleVisible}
        scrollEnabled={false}
        onClose={() => setRuleVisible(false)}>
        <DigitRule gameName={`Quick3D ${cycle}min`} />
      </RuleModal>
    </View>
  );
};

export default DigitChat;
