import TabHeader from '@/components/business/games/tab-header';
import React, {useMemo} from 'react';

export const tablist = [
  {
    title: 'chat',
    key: 'chat',
  },
  {
    title: 'result',
    key: 'result',
  },
  {
    title: 'order',
    key: 'order',
  },
];
const DigitTab = ({
  setTabKey = () => {},
  tabKey = 'chat',
  orderCount,
}: {
  tabKey?: string;
  orderCount?: number;
  setTabKey?: (v: string) => void;
}) => {
  const index = useMemo(() => {
    const _index = tablist.findIndex(item => item.key === tabKey);
    return _index > -1 ? _index : 0;
  }, [tabKey]);

  return (
    <TabHeader
      orderNum={orderCount}
      orderKey="order"
      routes={tablist}
      onTabPress={i => {
        setTabKey(tablist[i].key);
      }}
      index={index || 0}
    />
  );
};

export default React.memo(DigitTab);
