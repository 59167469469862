import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import DetailNavTitle from '@/components/business/detail-nav-title';
import {goBack} from '@/utils';
import React from 'react';
import Text from '@/components/basic/text';
import TriangleIcon from '@/components/svgs/basic/triangle';
import {View} from 'react-native';
import theme from '@/style';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

export interface NavHeaderProps {
  selectName?: string;
  onChange?: () => void;
  disabled?: boolean;
}

const NavHeader = ({selectName = '', onChange}: NavHeaderProps) => {
  const {calcActualSize} = useScreenSize();
  return (
    <DetailNavTitle
      hideServer={true}
      leftNode={
        selectName ? (
          <NativeTouchableOpacity
            style={[theme.flex.row, theme.flex.centerByCol]}
            onPress={() => onChange?.()}>
            <View
              style={[
                {
                  maxWidth: calcActualSize(140),
                },
              ]}>
              <Text numberOfLines={1} fontFamily="fontInterBold" size="large">
                {selectName}
              </Text>
            </View>
            {
              <View style={[theme.margin.leftxxs]}>
                <TriangleIcon />
              </View>
            }
          </NativeTouchableOpacity>
        ) : (
          '3D Prediction'
        )
      }
      onBack={() => {
        goBack();
      }}
      hideTitle
    />
  );
};

export default NavHeader;
