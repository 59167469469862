import {useRoute} from '@react-navigation/native';
import QuickDigitChat from './quick-digit-chat';
import React from 'react';
import PickDigit from './pick-digit';

const PickDigitContainer = () => {
  const {
    pickGameType = 'normal',
    pickName = 'Quick 3Digit',
    cycle = '3',
    id,
  } = (useRoute().params as {
    pickGameType?: string;
    pickName?: string;
    cycle?: string;
    id?: string;
  }) || {};

  if (pickGameType === 'quick') {
    return (
      <QuickDigitChat
        pickName={pickName}
        cycle={cycle}
        id={id}
        pickGameType={pickGameType}
      />
    );
  }

  return <PickDigit />;
};

export default PickDigitContainer;
