import React from 'react';
import {View} from 'react-native';
import {useResponsiveDimensions} from '@/utils';
import OrderList from './digit-order/order-list';
import theme from '@/style';
import DigitResult from './digit-result/result';
import DigitChat from './digit-chat';
import {betsListItem, PickInfosContent} from '../digit-service';
const DigitTabView = ({
  refreshCode,
  pickThreeId,
  tabKey,
  type = 'normal',
  remainMillis,
  cycle = 3,
  levelList = [],
  issueNo,
  onPaySubmit,
}: {
  refreshCode: number;
  pickThreeId?: number;
  tabKey?: string;
  type?: string;
  remainMillis?: number;
  cycle?: number;
  levelList?: PickInfosContent[];
  issueNo?: string;
  onPaySubmit?: (
    betsList: betsListItem[],
    priceAndNum: {total: number; num: number},
    cb?: () => void,
  ) => void;
}) => {
  const {height: screenHeight} = useResponsiveDimensions();

  return (
    <>
      <View
        style={[
          theme.flex.flex1,
          tabKey !== 'chat' ? {display: 'none'} : undefined,
        ]}>
        <DigitChat
          remainMillis={remainMillis}
          cycle={cycle}
          levelList={levelList}
          issueNo={issueNo}
          onPaySubmit={onPaySubmit}
        />
      </View>
      {tabKey !== 'chat' ? (
        <View
          style={[
            {
              minHeight: screenHeight - 100,
            },
          ]}>
          <View
            style={[
              // eslint-disable-next-line react-native/no-inline-styles
              {height: tabKey === 'result' ? '100%' : 0, overflow: 'hidden'},
            ]}>
            {tabKey === 'result' ? (
              <DigitResult
                type={type}
                refreshCode={refreshCode}
                pickThreeId={pickThreeId}
                isActive={tabKey === 'result'}
              />
            ) : null}
          </View>
          {tabKey === 'order' ? (
            <OrderList
              type={type}
              refreshCode={refreshCode}
              pickThreeId={pickThreeId}
              isActive={tabKey === 'order'}
            />
          ) : null}
        </View>
      ) : null}
    </>
  );
};

export default DigitTabView;
