import Spin from '@/components/basic/spin';
import theme from '@/style';
import {getUUID, goTo, transfor} from '@/utils';
import React, {FC, useState} from 'react';
import {ScrollView, View} from 'react-native';
import QuickDigitHeader from './components/quick-digit-v2/quick-digit-header';
import {
  betsListItem,
  crateQuickGame,
  DigitOrderResponse,
  DigitPickInfoQuickDataResponse,
  getDigitOrderList,
  getQuickPickData,
  PickGamesContent,
  quickCreateParams,
  QuickDigitOrderResponse,
  quickDrawByIssueNum,
  quickGameOrder,
} from './digit-service';
import globalStore from '@/services/global.state';
import DigitTabChat from './digit-tab/digit-tab-chat';
import {checkIsShown, saveIssueNum} from '@/components/utils/gameWin';
import {SafeAny} from '@/types';
import DigitTabViewChat from './digit-tab/digit-tab-view-chat';
import {
  getShareAward,
  postReport,
  TReportType,
  TSourceType,
} from '@/services/global.service';
import {useTranslation} from 'react-i18next';
import GoldWin from '@/components/business/games/gold-win';
import PaidShareModal from '@/components/business/paid-share-modal';
import NavHeader from './components/quick-digit-v2/nav-header';
import QuickDigitPicker, {
  QuickDigitPickerRef,
} from './components/quick-digit-v2/quick-digit-picker';
import Video from '@/components/basic/video';

interface QuickDigitChatProps {
  pickName?: string;
  cycle?: string;
  id?: string;
  pickGameType?: string;
}

const QuickDigitChat: FC<QuickDigitChatProps> = ({
  pickName,
  id,
  pickGameType,
  cycle,
}: QuickDigitChatProps) => {
  const queryWinId = React.useRef<NodeJS.Timeout>();
  const user = localStorage.getItem('user') || '{}';
  const userId = JSON.parse(user).userId;
  const picker = React.useRef<QuickDigitPickerRef>(null);

  const [loading, setLoading] = useState(false);
  const [quickData, setQuickData] =
    React.useState<DigitPickInfoQuickDataResponse>();
  const [disabled, setDisabled] = useState(false);
  const [quickQueryList, setQuickQueryList] =
    React.useState<PickGamesContent[]>();
  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  const timeCurrent = React.useRef(0);
  const [showWin, setShowWin] = React.useState(false);
  const [winAmount, setWinAmount] = React.useState(0);
  const [threeOrderData, setThreeOrderData] = React.useState<SafeAny>({});
  const [orderCount, setOrderCount] = React.useState(0);
  const [tabToTop, setTabToTop] = React.useState(0);
  const [tabKey, setTabKey] = React.useState('chat');
  const activeQuickInfo = React.useMemo<PickGamesContent | undefined>(() => {
    if (quickQueryList && quickQueryList.length > 0) {
      return quickQueryList[activeIndex];
    }
  }, [quickQueryList, activeIndex]);
  const [prizeVersion, setPrizeVersion] = React.useState(0);
  const scrollRef = React.useRef<ScrollView>(null);
  const [shareAward, setShareAward] = useState(0);
  const [popVisible, setPopVisible] = useState(false);
  const [remainMillis, setRemainMillis] = useState(0);
  const [winPaused, setWinPaused] = useState(true);
  const {t} = useTranslation();

  const queryWin = (selectInfo: PickGamesContent) => {
    quickDrawByIssueNum(
      selectInfo.pickThreeID,
      selectInfo.lastResult!.pickNo,
    ).then(async (result: number) => {
      if (result && result > 0) {
        // 中奖
        const isShown = await checkIsShown(
          'digit',
          selectInfo.lastResult!.pickNo,
        );
        if (!isShown) {
          const getRewarOrder = activeQuickInfo
            ? () =>
                quickGameOrder({
                  pickThreeId: activeQuickInfo?.pickThreeID,
                  pageNo: 1,
                  pageSize: 1,
                  orderStatus: 3,
                })
            : () => getDigitOrderList(1, 1);
          getRewarOrder().then(r => {
            setThreeOrderData({
              userId,
              data: transfor(
                activeQuickInfo
                  ? (r as QuickDigitOrderResponse)?.records[0]
                  : (r as DigitOrderResponse)?.content[0],
                pickGameType === 'normal' ? '3Digit' : 'quick3d',
                {
                  from: 'order',
                },
              ),
            });
          });
          setWinAmount(result);
          setShowWin(true);
          setWinPaused(false);
          globalStore.updateAmount.next({gameType: '3Digit'});
          saveIssueNum('digit', selectInfo.lastResult!.pickNo);
        }
      }
    });
  };
  const getQuickConfig = async (active?: number) => {
    try {
      setLoading(true);
      const res = await getQuickPickData();
      if (res) {
        const games = res.pickGames;
        const currentI = games.findIndex(
          (item: {cycle: number}) => item.cycle === Number(cycle),
        );
        console.log('active', active);
        const activeI =
          active !== undefined ? active : currentI === -1 ? 0 : currentI;
        const selectInfo = games[activeI];
        // setCutdownTime(selectInfo.drawTimeLong);
        setQuickData(res);
        setQuickQueryList(res.pickGames);
        if (activeI != null) {
          setActiveIndex(activeI);
        }
        if (globalStore.token) {
          if (timeCurrent.current > 5) {
            queryWin(selectInfo);
          } else {
            queryWinId.current = setTimeout(() => {
              queryWin(selectInfo);
            }, timeCurrent.current * 1000);
          }
        }
      }
    } finally {
      setDisabled(false);
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (id) {
      getQuickConfig();
    }
    return () => {
      queryWinId.current && clearTimeout(queryWinId.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleTimeEnd = () => {
    // console.log('!!!!!!!!!!!!!!!!onTimeEnd!!!!!!!!!!!!!!');
    getQuickConfig(activeIndex).finally(() => {
      setPrizeVersion(prizeVersion + 1);
    });
  };

  const handleAnalyze = () => {
    if (tabKey !== 'result') {
      setTabKey('result');
    }
    setTimeout(() => {
      scrollRef?.current?.scrollTo({y: tabToTop});
    }, 0);
  };
  const handleTabKey = (key: string) => {
    if (key === 'order') {
      setOrderCount(0);
    }
    setTabKey(key);
  };
  const handleTimeChange = (_remainMillis: number) => {
    setRemainMillis(_remainMillis);
  };

  const handlePaySubmit = async (
    betsList: betsListItem[],
    priceAndNum: {total: number; num: number},
    cb?: () => void,
  ) => {
    if (disabled || loading) {
      return;
    }
    if (!globalStore.token) {
      goTo('Login');
      return;
    }
    try {
      globalStore.globalLoading.next(true);
      let res: string = '';
      let pickData: quickCreateParams;

      if (activeQuickInfo) {
        pickData = {
          pickThreeId: activeQuickInfo.pickThreeID,
          pickNo: activeQuickInfo?.currentPickNo,
          totalAmount: priceAndNum.total,
          tickets: betsList.map(item => {
            return {
              pickInfoId: item.pickInfoId,
              pickIndex: item.type,
              pickNumber: item.value,
              pickCount: item.count,
            };
          }),
        };
        res = await crateQuickGame(pickData);
      }

      if (res) {
        const shareUserId = localStorage.getItem('shareUserId');
        const [imStr, imGameCode, gameName, issueNo] = (
          localStorage.getItem('PlayNow') || '-'
        ).split('-');
        if (shareUserId && imStr === 'im' && imGameCode === 'Digit') {
          postReport({
            reportType: TReportType.BETS,
            gameName,
            gameType: pickGameType === 'normal' ? '3Digit' : 'quick3d',
            userId,
            issueNo,
            sourceType: TSourceType.IM,
            shareUserId,
          });
          localStorage.removeItem('PlayNow');
        }
        if (!globalStore.imIcon) {
          globalStore.globalSucessTotal(t('paidSuccess.label.subTitle'));
        }
        getShareAward(pickGameType === 'normal' ? '3Digit' : 'quick3d').then(
          award => {
            setShareAward(Math.floor(award));
            setPopVisible(true);
            const getOrder = activeQuickInfo
              ? () =>
                  quickGameOrder({
                    pickThreeId: activeQuickInfo?.pickThreeID,
                    pageNo: 1,
                    pageSize: 1,
                    orderStatus: 3,
                  })
              : () => getDigitOrderList(1, 1);
            getOrder().then(r => {
              setThreeOrderData({
                userId,
                data: transfor(
                  {...pickData, pickInfos: quickData?.pickInfos},
                  pickGameType === 'normal' ? '3Digit' : 'quick3d',
                  {
                    rest: {
                      gameIconUrl: activeQuickInfo
                        ? (r as QuickDigitOrderResponse)?.records[0]?.gameIcon
                        : (r as DigitOrderResponse)?.content[0]?.gameIconUrl,
                      gameName: activeQuickInfo
                        ? (r as QuickDigitOrderResponse)?.records[0]?.timeName
                        : pickName,
                      issNo: activeQuickInfo
                        ? (r as QuickDigitOrderResponse)?.records[0]?.orderGroup
                        : (r as DigitOrderResponse)?.content[0]?.orderGroup,
                      openTime: activeQuickInfo
                        ? (r as QuickDigitOrderResponse)?.records[0]
                            ?.gameDrawTime
                        : (r as DigitOrderResponse)?.content[0]?.gameDrawTime,
                      totalAmount: pickData.totalAmount,
                    },
                  },
                ),
              });
            });
          },
        );
        if (tabKey !== 'order') {
          setOrderCount(oc => oc + 1);
        }
        globalStore.updateAmount.next({gameType: '3Digit'});
        cb?.();
      }
    } finally {
      globalStore.globalLoading.next(false);
    }
  };
  return (
    <View style={[theme.background.lightGrey, theme.fill.fill, theme.flex.col]}>
      <NavHeader
        selectName={
          activeQuickInfo?.timeName
            ? `Quick 3D-${activeQuickInfo?.timeName}`
            : undefined
        }
        onChange={() => picker.current?.open()}
      />
      <Spin loading={loading} style={[theme.flex.flex1, theme.overflow.hidden]}>
        <View
          style={[theme.flex.flex1, theme.flex.basis0, theme.position.rel]}
          onLayout={e => setTabToTop(e.nativeEvent.layout.height)}>
          <ScrollView
            style={tabKey === 'chat' ? theme.flex.flex1 : undefined}
            contentContainerStyle={
              tabKey === 'chat' ? theme.flex.flex1 : undefined
            }
            ref={scrollRef}
            stickyHeaderIndices={[1]}>
            <QuickDigitHeader
              issueNo={activeQuickInfo?.currentPickNo || '--'}
              cycle={activeQuickInfo?.cycle}
              numResult={activeQuickInfo?.lastResult?.drawResult || '000'}
              prizeVersion={prizeVersion}
              onTimeEnd={handleTimeEnd}
              minBetSeconds={activeQuickInfo?.stopBetSec}
              remainingTime={activeQuickInfo?.drawTimeLong}
              onAnalyze={handleAnalyze}
              onTimeChange={handleTimeChange}
            />
            <DigitTabChat
              orderCount={orderCount}
              tabKey={tabKey}
              setTabKey={handleTabKey}
            />
            <DigitTabViewChat
              issueNo={activeQuickInfo?.currentPickNo}
              refreshCode={prizeVersion}
              type={pickGameType}
              remainMillis={remainMillis}
              cycle={activeQuickInfo?.cycle}
              pickThreeId={
                pickGameType === 'normal'
                  ? Number(id)
                  : activeQuickInfo?.pickThreeID
              }
              tabKey={tabKey}
              levelList={quickData?.pickInfos}
              onPaySubmit={handlePaySubmit}
            />
          </ScrollView>
          <QuickDigitPicker
            ref={picker}
            activeIndex={activeIndex}
            timeList={quickData?.pickGames}
            onItemSelect={index => {
              setActiveIndex(index);
            }}
          />
        </View>
      </Spin>
      <PaidShareModal
        popVisible={popVisible}
        setPopVisible={setPopVisible}
        zIndex={10005}
        shareAward={shareAward}
        sharInfo={{
          gameCode: pickGameType === 'normal' ? '3Digit' : 'quick3d',
          orderNo: getUUID(),
          threeOrderData,
        }}
      />
      <GoldWin
        amount={winAmount}
        visible={showWin}
        shareAward={shareAward}
        onClose={() => {
          setShowWin(false);
        }}
        sharInfo={{
          gameCode: 'quick3d',
          orderNo: getUUID(),
          threeOrderData,
        }}
      />
      <Video
        onEnd={() => {
          setWinPaused(true);
          // videoRef.current?.seek(0);
        }}
        audioOnly
        source={require('@components/assets/music/win.mp3')}
        src={'/assets/mp3/win.mp3'}
        paused={winPaused}
      />
    </View>
  );
};

export default QuickDigitChat;
