import theme from '@/style';
import React, {useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import Text from '@basicComponents/text';
import {useTranslation} from 'react-i18next';
import BackgroundTimer from '@components/utils/timer';
import {SafeAny} from '@/types';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import {ReactComponent as DigitClock} from '@/assets/icons/games/digit-clock.svg';
import './quick-digit-header-status.css';
interface QuickDigitHeaderStatusProps {
  minBetSeconds?: number;
  remainingTime?: number;
  onTimeEnd?: () => void;
  onTimeChange?: (remainMillis: number) => void;
  issueNo?: string;
}
const QuickDigitHeaderStatus = ({
  minBetSeconds = 30,
  remainingTime = 0,
  onTimeEnd,
  onTimeChange,
  issueNo,
}: QuickDigitHeaderStatusProps) => {
  const {t} = useTranslation();
  const timer = React.useRef<SafeAny>(null);
  const [endTimeMillis, setEndTimeMillis] = useState(0);
  const [remainMillis, setRemainMillis] = useState(0);
  const {calcActualSize} = useScreenSize();

  useEffect(() => {
    setEndTimeMillis(Date.now() + remainingTime * 1000);
  }, [remainingTime]);

  React.useEffect(() => {
    if (remainingTime <= 0) {
      return;
    }
    timer.current = BackgroundTimer.setInterval(() => {
      const _remainMillis = endTimeMillis - Date.now();
      if (_remainMillis <= 0) {
        timer.current && BackgroundTimer.clearInterval(timer.current);
        onTimeEnd?.();
      }
      onTimeChange?.(Math.max(_remainMillis, 0));
      setRemainMillis(Math.max(_remainMillis, 0));
    }, 100);
    return () => timer.current && BackgroundTimer.clearInterval(timer.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTimeMillis]);

  const remainTimeStr = React.useMemo(() => {
    const m = Math.floor((remainMillis % 3600000) / 60000);
    const s = Math.floor((remainMillis % 60000) / 1000);
    const msf = Math.floor((remainMillis % 1000) / 100);
    return `${m.toString().padStart(2, '0')}:${s
      .toString()
      .padStart(2, '0')}:${msf}`;
  }, [remainMillis]);

  const status = React.useMemo(() => {
    if (remainMillis < 4 * 1000) {
      return 'countdown';
    }
    if (remainMillis < minBetSeconds * 1000) {
      return 'drawing';
    }
    return 'purchasing';
  }, [remainMillis, minBetSeconds]);

  const styles = StyleSheet.create({
    remainTime: {
      gap: calcActualSize(4),
      top: '50%',
      left: '50%',
      transform: [{translateX: '-50%'}, {translateY: '-50%'}] as any[],
    },
  });

  return (
    <View style={[theme.flex.col, {padding: calcActualSize(4)}]}>
      <View
        style={[
          theme.flex.row,
          theme.flex.between,
          theme.flex.centerByCol,
          theme.position.rel,
          {
            backgroundColor: 'rgba(0, 0, 0, 0.60)',
            borderRadius: 9999,
            paddingHorizontal: calcActualSize(8),
            paddingVertical: calcActualSize(4),
          },
        ]}>
        <View
          style={[
            theme.flex.row,
            theme.flex.centerByCol,
            {gap: calcActualSize(8)},
          ]}>
          <div
            className={`quick-digit-header-status-indicator ${
              status !== 'purchasing' ? 'status-drawing' : ''
            }`}
          />
          <Text calc size="small" color="white">
            {t(`game-page.label.${status}`)}
          </Text>
        </View>
        <Text calc size="medium" color="white">
          {issueNo}
        </Text>
        <View
          style={[
            theme.position.abs,
            theme.flex.row,
            theme.flex.centerByCol,
            styles.remainTime,
          ]}>
          <DigitClock width={calcActualSize(20)} height={calcActualSize(20)} />
          <Text calc size="large" color={'#FDE047'} blod>
            {remainTimeStr}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default QuickDigitHeaderStatus;
