import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import React from 'react';
import {useTranslation} from 'react-i18next';
import DigitBall from '../../components/digit-ball';
import OrderListItemStatus from '@/components/business/order/order-list-item-status';
import Price from '@/components/business/price';
import {DigitOrderCodeItem} from '../../digit-service';
import {BasicObject} from '@/types';
import MoreBtn from '@/components/business/order/more-btn';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

const whenNumToHide = 5;
const OrderBetList = ({codeLists = []}: {codeLists: DigitOrderCodeItem[]}) => {
  const {i18n} = useTranslation();
  const [showMore, setShowMore] = React.useState(false);
  const list = React.useMemo(() => {
    if (codeLists.length <= whenNumToHide) {
      return codeLists;
    } else {
      return showMore ? codeLists : codeLists.slice(0, whenNumToHide);
    }
  }, [showMore, codeLists]);

  const getABC = (indexCode: string) => {
    if (!indexCode) {
      return ['A', 'B', 'C'].map(key => ({key, value: '-'}));
    }
    const [alphaCodes, digitCodes] = indexCode.split('=').map(v => v.split(''));
    const codeMap = alphaCodes.reduce((a, b, i) => {
      a[b] = digitCodes[i];
      return a;
    }, {} as BasicObject);
    const result = ['A', 'B', 'C'].map(key => ({
      key,
      value: codeMap[key] || '-',
    }));
    return result;
  };
  const {calcActualSize} = useScreenSize();

  return (
    <View style={[theme.margin.lrl]}>
      <View
        style={[
          theme.flex.row,
          styles.header,
          theme.flex.between,
          theme.flex.centerByCol,
        ]}>
        <View
          style={[
            theme.flex.row,
            {gap: theme.paddingSize.xxs},
            theme.margin.rightl,
          ]}>
          {['A', 'B', 'C'].map((item, _i) => (
            <DigitBall
              key={_i}
              digit={item}
              color={item}
              ballSize={calcActualSize(24)}
            />
          ))}
        </View>
        <View style={[theme.flex.flex1, theme.flex.center]}>
          <Text calc>{i18n.t('bets-detail.label.payment').toUpperCase()}</Text>
        </View>
        <View style={[theme.flex.flex1, theme.flex.alignEnd]}>
          <Text calc>{i18n.t('bets-detail.label.result').toUpperCase()}</Text>
        </View>
      </View>
      <View>
        {list.map((item, _i) => {
          const abcArr = getABC(item.indexCode);
          return (
            <View
              key={_i}
              style={[
                theme.flex.row,
                theme.padding.lrl,
                theme.flex.centerByCol,
                // eslint-disable-next-line react-native/no-inline-styles
                {
                  backgroundColor: _i % 2 === 0 ? '#FFF' : '#F8F9FF',
                  height: calcActualSize(48),
                },
              ]}>
              <View
                style={[
                  theme.flex.row,
                  {gap: theme.paddingSize.xxs},
                  theme.margin.rightl,
                ]}>
                {abcArr.map((pie, _p) => (
                  <DigitBall
                    key={_p}
                    digit={pie.value}
                    color={pie.key}
                    ballSize={calcActualSize(24)}
                  />
                ))}
              </View>
              <View
                style={[theme.flex.row, theme.flex.center, theme.flex.flex1]}>
                <Price price={item.pickAmount} textProps={{size: 'medium'}} />
                <Text calc size="medium" fontFamily="fontDinBold">
                  *{item.pickCount}
                </Text>
              </View>
              <OrderListItemStatus
                hasDetail={false}
                status={
                  item?.wonCode.startsWith('*')
                    ? 2
                    : item.winAmount > 0 || item.codeWinAmount > 0
                    ? 1
                    : 0
                }
                awardAmount={item.winAmount || item.codeWinAmount}
              />
            </View>
          );
        })}
      </View>
      {codeLists.length > whenNumToHide && !showMore && (
        <MoreBtn onPress={() => setShowMore(true)} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    backgroundColor: '#E9ECF5',
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
});

export default React.memo(OrderBetList);
